import { useSearchParams, useParams } from 'next/navigation';

function useQueryParams() {
  const searchParams = useSearchParams();
  const params = useParams();

  const currentSearchParams: { [key: string]: string } = searchParams
    ? Object.fromEntries(searchParams.entries())
    : {};

  const currentDynamicParams: { [key: string]: string | string[] } =
    params || {};

  const currentRouterQuery = {
    ...currentSearchParams,
    ...currentDynamicParams
  };

  return { currentRouterQuery };
}

export default useQueryParams;
