import adminProtectedAxiosRequest from './helpers/adminProtectedAxiosRequest';
import config from '../utility/config';
import { getAxiosError, getAxiosErrorMsg } from './helpers';
import unAuthorizedRequest from './helpers/unAuthorizedRequest';
import { convertObjectToQueryString } from './helpers/queryString';
import publicAxiosRequest from './helpers/publicAxiosRequest';
const { communityApiBasePath, loginApiRoute } = config;

export const userLoginService = async (
  email,
  password,
  communityMandatory
) => {
  try {
    const path = `${loginApiRoute}/api/v1/log-in`;
    const payload = {
      email,
      password,
      communityMandatory: communityMandatory // So user cannot login to an account with no communities
    };

    return await publicAxiosRequest.post(path, payload, {
      withCredentials: true
    });
  } catch (e) {
    return { error: e.message, data: {} };
  }
};

/**
 * Payload should send either authToken or refreshToken
 * @param {*} payload | Object
 * @param {*} payload.authToken | String | For Social Logins
 * @param {*} payload.refreshToken | String
 * @returns Promise
 */
export const getTokensService = async (payload) => {
  try {
    const path = `${loginApiRoute}/api/v1/get-token`;
    return await publicAxiosRequest.post(path, payload, {
      withCredentials: true
    });
  } catch (e) {
    return { error: e.message };
  }
};

/**
 * @param token - the user token, you want to use to get the user profile, if not passed, it will use the current logged in user token
 * @param params - we can pass activeCommunityId to get the user profile for a specific community
 * @param params.activeCommunityId
 */
export const getUserProfile = async (token, params) => {
  try {
    const config = token
      ? {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      : null;

    const queryString = params
      ? `?${convertObjectToQueryString(params)}`
      : '';

    return await adminProtectedAxiosRequest.get(
      `${communityApiBasePath}/api/v1/user-profile${queryString}`,
      config
    );
  } catch (e) {
    return getAxiosError(e);
  }
};

export const updateUserProfile = async (payload) => {
  try {
    return await adminProtectedAxiosRequest.put(
      `${communityApiBasePath}/api/v1/update-profile`,
      payload
    );
  } catch (e) {
    return getAxiosError(e);
  }
};

export const renewPasswordWithToken = async ({
  password,
  confirmedPassword,
  token
}) => {
  const payload = {
    password,
    password2: confirmedPassword
  };
  const apiUrl = `${communityApiBasePath}/api/v1/reset-password/${token}`;
  return await publicAxiosRequest.post(apiUrl, payload);
};

export const rewnewPasswordWithoutToken = async ({
  password,
  confirmedPassword,
  oldPassword
}) => {
  const payload = {
    password,
    password2: confirmedPassword,
    oldPassword
  };
  const apiUrl = `${communityApiBasePath}/api/v1/auth/reset-password`;
  return await adminProtectedAxiosRequest.post(apiUrl, payload);
};

export const updateUserBasicProfile = async (payload) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/update-basic-profile`;
    return await adminProtectedAxiosRequest.post(apiPath, payload);
  } catch (error) {
    return getAxiosError(error);
  }
};

export const sendOtpToEmail = async (email) => {
  const apiPath = `${loginApiRoute}/api/v1/otp/sendMail`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email })
  };

  const { data, error } = await unAuthorizedRequest(apiPath, options);

  if (error) {
    return { error: getAxiosErrorMsg(error) };
  }

  return { data: data, error: false };
};

export const validateEmailOtp = async ({ otpToken, email, otp }) => {
  try {
    const apiPath = `${loginApiRoute}/api/v1/otp/validate`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ otpToken, email, otp })
    };

    const { data, error } = await unAuthorizedRequest(apiPath, options);
    if (error) {
      throw new Error(error);
    }
    return { data: data, error: false };
  } catch (err) {
    return { error: getAxiosErrorMsg(err) };
  }
};

export const updatePassword = async ({
  passwordToken,
  password,
  email
}) => {
  try {
    const apiPath = `${loginApiRoute}/api/v1/password`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ passwordToken, password, email })
    };

    const { data, error } = await unAuthorizedRequest(apiPath, options);
    if (error) {
      throw new Error(error);
    }
    return { data: data, error: false };
  } catch (err) {
    return { error: getAxiosErrorMsg(err) };
  }
};

export const updateUserPhoneConfirmation = () =>
  adminProtectedAxiosRequest.put(
    `${config.communityApiBasePath}/api/v1/confirm-phoneNumber`
  );

export const getProfileByRoleType = () =>
  adminProtectedAxiosRequest.get(
    `${config.communityApiBasePath}/api/v1/user-profile/contents`
  );

/**
 * Deletes the user account.
 * @returns {Promise} A promise that resolves when the account is deleted.
 */
export const deleteAccount = async () => {
  return await adminProtectedAxiosRequest.delete(
    `${loginApiRoute}/api/v1/mobile/delete-account`,
    config
  );
};

export const forgotPassword = async ({ email }) => {
  return await publicAxiosRequest.post(
    `${loginApiRoute}/api/v1/forget-password`,
    {
      email
    }
  );
};

export default {
  updatePassword,
  renewPasswordWithToken,
  rewnewPasswordWithoutToken
};
