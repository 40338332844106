export const [
  CHECKOUT_TYPE_COURSE,
  CHECKOUT_TYPE_BUNDLE,
  CHECKOUT_TYPE_COMMUNITY
] = ['course', 'bundle', 'community'];

export const [ACTION_CREATE_BUNDLE, ACTION_CHECKOUT_BUNDLE] = [
  'create',
  'checkout'
];

// checkout payment step views
export const [
  DEFAULT_PAYMENT_VIEW,
  OTHER_PAYMENT_OPTIONS_VIEW,
  DISCOUNT_CODE_VIEW
] = ['default', 'others', 'discount'];

// payment methods
export const DEFAULT_PAYMENT_METHOD = 'card';

// Payment types
export const [FULL_PAYMENT, DEPOSIT_PAYMENT] = ['full', 'deposit'];

// Discount types
export const [DISCOUNT_TYPE_PERCENT_OFF, DISCOUNT_TYPE_AMOUNT_OFF] = [
  'percent_off',
  'amount_off'
];

// Gift card coupon code type
export const GIFT_CODE_TYPE = 'giftCard';

// Payment Status values
export const [PAYMENT_SUCCESS, PAYMENT_FAILURE, PAYMENT_PENDING] = [
  'complete',
  'failed',
  'pending'
];

// free sponsored ondemand courses
export const FREE_ONDEMAND_COURSES = [
  'EPIDEMIC_SOUND_DESIGN',
  'VAYNER_10_STEPS_NFT'
];

// data from coupon provided in URL
export const COUPON_URI_KEY = 'coupon';
export const COUPON_SESSION_KEY = 'couponQueryParam';
export const SPECIAL_REDIRECT_PERFORMED_KEY = 'specialRedirectPerformed';

// checkout session keys
export const SIGN_UP_FULL_NAME_SESSION_KEY = 'signupFullName';
export const SIGN_UP_EMAIL_SESSION_KEY = 'signupEmail';
export const SIGN_UP_PHONE_NUMBER_SESSION_KEY = 'signupPhoneNumber';
export const SIGN_UP_ID_SESSION_KEY = 'signupId';
export const USER_ID_SESSION_KEY = 'userId';
export const SIGN_UP_ACCESS_TOKEN_SESSION_KEY = 'accessToken';
export const SIGN_UP_USER_CREDITS_SESSION_KEY = 'signupUserCredits';
export const SIGN_UP_SLUG_SESSION_KEY = 'signupSlug';
export const SIGN_UP_PRICE_ID = 'signupPriceId';

// course offer types
export const LIVE_COURSE_OFFER_TYPE = 'live';
export const PRE_RECORDED_COURSE_OFFER_TYPE = 'preRecorded';
export const COHORT_ON_DEMAND_COURSE_OFFER_TYPE = 'cohortOnDemand';
export const VIDEO_ON_DEMAND_COURSE_OFFER_TYPE = 'videoOnDemand';
export const FREE_WORKSHOP_COURSE_OFFER_TYPE = 'freeWorkshop';
export const FELLOWSHIP_COURSE_TYPE = 'fellowship';

// modes
export const MODE_LIVE = 'live';
export const MODE_COHORT_LIVE = 'cohortlive';
export const MODE_ONDEMAND = 'ondemand';
export const MODE_VIDEO_ONDEMAND = 'videoondemand';
export const MODE_FREE_WORKSHOP_COURSE = 'freeworkshop';
export const MODE_GIFT_CARD = 'giftcard';

export const MODE_COHORT_ONDEMAND = 'cohortondemand';

export const CHECKOUT_QUERY_PARAMS = 'checkout-popup';
export const LIVE_COURSE_ONE_SESSION_OFFER_TYPE = 'liveOneSession'; //for UI purpose only
export const FELLOWSHIP_MODAL_OFFER_TYPE = 'fellowshipModal'; //for UI purpose only
export const FELLOWSHIP_REDIRECT_OFFER_TYPE = 'fellowshipRedirect'; //for UI purpose only
export const DEFAULT_CURRENCY = 'USD';

// course modes with hidden course tag card
export const HIDDEN_COURSE_TAG_COURSE_MODES = [
  MODE_ONDEMAND,
  MODE_VIDEO_ONDEMAND
];

export const HIDDEN_COURSE_TAG_COURSES = ['VAYNER_10_STEPS_NFT'];

// course offer types with hidden course tag card
export const HIDDEN_COURSE_TAG_COURSE_OFFER_TYPES = [
  PRE_RECORDED_COURSE_OFFER_TYPE,
  VIDEO_ON_DEMAND_COURSE_OFFER_TYPE
];
