/** AUTO-GENERATED - Run the command "bun run icon-name-update" to update this file **/
export default {
  common: [
    '1-1-session',
    'Pause',
    'activity-heart',
    'activity',
    'airplay',
    'airpods',
    'alarm-clock-check',
    'alarm-clock-minus',
    'alarm-clock-off',
    'alarm-clock-plus',
    'alarm-clock',
    'alert-circle-filled',
    'alert-circle',
    'align-bottom-02',
    'align-center',
    'align-horizontal-centre-01',
    'align-horizontal-centre-02',
    'align-justify',
    'align-left-02',
    'align-left',
    'align-right-02',
    'align-right',
    'align-top-arrow-02',
    'align-vertical-center-01',
    'align-vertical-center-02',
    'anchor',
    'announcement-03',
    'archive',
    'arrow-circle-down-left',
    'arrow-circle-down-right',
    'arrow-circle-down',
    'arrow-circle-left',
    'arrow-circle-right',
    'arrow-circle-up-left',
    'arrow-circle-up-right',
    'arrow-circle-up',
    'arrow-down-left',
    'arrow-down-right',
    'arrow-down',
    'arrow-left',
    'arrow-right',
    'arrow-up-left',
    'arrow-up-right',
    'arrow-up',
    'ataflow-03',
    'atom-01',
    'attachment-01',
    'award-01',
    'award-03',
    'award-04',
    'award-05',
    'backpack',
    'bank-note-01',
    'bank-note-02',
    'bank',
    'bar-chart-01',
    'bar-chart-02',
    'bar-chart-03',
    'bar-chart-circle-01',
    'bar-chart-circle-02',
    'bar-chart-circle-03',
    'bar-chart-square-01',
    'bar-chart-square-02',
    'bar-chart-square-03',
    'bar-chart-square-down',
    'bar-chart-square-minus',
    'bar-chart-square-plus',
    'bar-chart-square-up',
    'bar-line-chart',
    'battery-charging-01',
    'battery-empty',
    'battery-full',
    'battery-low',
    'battery-mid',
    'beaker-01',
    'beaker-02',
    'bell-01',
    'bell-minus',
    'bell-off-01',
    'bell-plus',
    'bell-ringing-01',
    'bezier-curve-01',
    'bezier-curve-02',
    'bezier-curve-03',
    'bluetooth-connect',
    'bluetooth-on',
    'bluetooth-signal',
    'bluetooth',
    'bold-01',
    'book-closed',
    'book-open-01',
    'bookmark-add',
    'bookmark-check',
    'bookmark-x',
    'bookmark',
    'box',
    'brackets-check',
    'brackets-minus',
    'brackets-slash',
    'brackets-x',
    'brackets',
    'briefcase-02',
    'browser',
    'brush-01',
    'brush-02',
    'building-02',
    'building-03',
    'building-04',
    'building-05',
    'bus',
    'calculator',
    'calendar-check-01',
    'calendar-date',
    'calendar-heart-01',
    'calendar-minus-01',
    'calendar-money',
    'calendar-plus-01',
    'calendar-range-01',
    'calendar',
    'camera-01',
    'camera-lens',
    'camera-off',
    'camera-plus',
    'certificate-01',
    'chart-breakout-circle',
    'check-circle-filled',
    'check-circle',
    'check-heart',
    'check-verified-filled',
    'check-verified',
    'check',
    'chevron-collapse',
    'chevron-down',
    'chevron-left',
    'chevron-right',
    'chevron-selector-horizontal',
    'chevron-selector-vertical',
    'chevron-up',
    'chrome-cast',
    'circle-cut',
    'clipboard-attachment',
    'clipboard-check',
    'clipboard-download',
    'clipboard-minus',
    'clipboard-plus',
    'clipboard-x',
    'clipboard',
    'clock-check',
    'clock-fast-forward',
    'clock-plus',
    'clock-refresh',
    'clock-rewind',
    'clock-snooze',
    'clock-stopwatch',
    'clock',
    'cloud-01',
    'cloud-02',
    'cloud-03',
    'cloud-blank-01',
    'cloud-lightning',
    'cloud-moon',
    'cloud-off',
    'cloud-raining-01',
    'cloud-raining-02',
    'cloud-raining-03',
    'cloud-raining-04',
    'cloud-raining-05',
    'cloud-raining-06',
    'cloud-snowing-01',
    'cloud-snowing-02',
    'cloud-sun-01',
    'cloud-sun-02',
    'cloud-sun-03',
    'code-01',
    'code-02',
    'code-browser',
    'code-circle-01',
    'code-circle-02',
    'code-circle-03',
    'code-square-01',
    'code-square-02',
    'codepen',
    'coins-01',
    'coins-02',
    'coins-hand',
    'coins-stacked-01',
    'coins-stacked-03',
    'coins-stacked-04',
    'coins-swap-02',
    'colors',
    'columns-02',
    'columns-03',
    'command',
    'compass-01',
    'compass',
    'container',
    'contrast-01',
    'contrast-02',
    'contrast-03',
    'copy',
    'cpu-chip-01',
    'cpu-chip-02',
    'credit-card-02',
    'credit-card-check',
    'credit-card-down',
    'credit-card-download',
    'credit-card-edit',
    'credit-card-lock',
    'credit-card-minus',
    'credit-card-plus',
    'credit-card-refresh',
    'credit-card-search',
    'credit-card-shield',
    'credit-card-up',
    'credit-card-upload',
    'credit-card-x',
    'crop-01',
    'cryptocurrency-01',
    'cryptocurrency-02',
    'cryptocurrency-03',
    'cryptocurrency-04',
    'cube-01',
    'cube-02',
    'cube-03',
    'cube-04',
    'cube-outline',
    'currency-bitcoin-circle',
    'currency-bitcoin',
    'currency-dollar-circle',
    'currency-dollar',
    'currency-ethereum-circle',
    'currency-ethereum',
    'currency-euro-circle',
    'currency-euro',
    'currency-pound-circle',
    'currency-pound',
    'currency-ruble-circle',
    'currency-ruble',
    'currency-rupee-circle',
    'currency-rupee',
    'currency-yen-circle',
    'currency-yen',
    'cursor-01',
    'cursor-02',
    'cursor-04',
    'cursor-box',
    'cursor-click-01',
    'cursor-click-02',
    'data',
    'database-02',
    'database-03',
    'dataflow-01',
    'dataflow-02',
    'dataflow-04',
    'delete',
    'diamond-01',
    'dice-1',
    'dice-2',
    'dice-3',
    'dice-4',
    'dice-5',
    'dice-6',
    'disc-02',
    'distribute-spacing-horizontal',
    'distribute-spacing-vertical-filled',
    'distribute-spacing-vertical',
    'divide-01',
    'divider',
    'dotpoints-01',
    'dots-horizontal',
    'dots-reorder-drag',
    'dots-vertical',
    'download-01',
    'download-02',
    'download-cloud-01',
    'drop',
    'droplets-01',
    'droplets-02',
    'droplets-03',
    'dropper',
    'duplicate',
    'edit',
    'eraser',
    'expand-01',
    'expand-width',
    'eye-first',
    'eye-off',
    'eye-second',
    'eye',
    'face-frown',
    'face-happy',
    'face-id',
    'face-neutral',
    'face-smile',
    'face-wink',
    'fast-backward',
    'fast-forward',
    'feather',
    'figma',
    'file-01',
    'file-02',
    'file-03',
    'file-attachment-04',
    'file-check-01',
    'file-code-01',
    'file-code-02',
    'file-download-01',
    'file-heart-01',
    'file-lock-03',
    'file-minus-01',
    'file-plus-01',
    'file-question-01',
    'file-search-01',
    'file-shield-03',
    'file-x-01',
    'film-01',
    'film-02',
    'filter-funnel-01',
    'filter-lines',
    'fingerprint-03',
    'flag-01',
    'flag-02',
    'flash-off',
    'flash',
    'flex-align-bottom',
    'flex-align-left',
    'flex-align-right',
    'flex-align-top',
    'flip-backward',
    'flip-forward',
    'folder-check',
    'folder-closed',
    'folder-code',
    'folder-download',
    'folder-lock',
    'folder-minus',
    'folder-plus',
    'folder-question',
    'folder-search',
    'folder-shield',
    'folder-x',
    'folder',
    'framer',
    'gaming-pad-01',
    'gift-01',
    'git-branch-01',
    'git-branch-02',
    'git-commit',
    'git-merge',
    'git-pull-request',
    'glasses-01',
    'glasses-02',
    'globe-02',
    'globe-04',
    'globe-05',
    'globe-06',
    'globe-search-02',
    'graduation-hat-01',
    'graduation-hat-02',
    'grid-01',
    'grid-02',
    'grid-03',
    'grid',
    'h1',
    'h2',
    'h3',
    'hand',
    'hard-drive',
    'hash-01',
    'hash-02',
    'heading-01',
    'headphones-01',
    'headphones-02',
    'heart-circle',
    'heart-filled',
    'heart-hand',
    'heart',
    'help-circle',
    'hexagon-01',
    'hexagon-02',
    'hield-03',
    'home-smile',
    'home',
    'hone-01',
    'hourglass-01',
    'hourglass-03',
    'hurricane-01',
    'hurricane-02',
    'hurricane-03',
    'image-01',
    'image-check',
    'image-down',
    'image-indent-left',
    'image-indent-right',
    'image-left',
    'image-plus',
    'image-right',
    'image-up',
    'image-user-check',
    'image-user-down',
    'image-user-left',
    'image-user-plus',
    'image-user-right',
    'image-user-up',
    'image-user-x',
    'image-user',
    'image-x',
    'inbox-01',
    'infinity',
    'info-circle-filled',
    'info-circle',
    'intersect-circle',
    'intersect-square',
    'italic-01',
    'key-01',
    'keyboard-02',
    'keyboard-dismiss',
    'keyboard-show',
    'laptop-02',
    'layer-single',
    'layers-three-01',
    'layers-three-02',
    'layers-two-01',
    'layers-two-02',
    'layout-alt-01',
    'layout-alt-02',
    'layout-alt-03',
    'layout-alt-04',
    'layout-bottom',
    'layout-grid-01',
    'layout-grid-02',
    'layout-left',
    'layout-right',
    'layout-top',
    'left-indent-01',
    'left-indent-02',
    'letter-spacing-01',
    'letter-spacing-02',
    'lightbulb-01',
    'lightbulb-02',
    'lightning-01',
    'lightning-02',
    'lightning-filled-01',
    'line-chart-down-02',
    'line-chart-down-03',
    'line-chart-up-02',
    'line-chart-up-03',
    'line-height',
    'link-01',
    'link-broken-02',
    'link-external-01',
    'loading-01',
    'loading-02',
    'lock-01',
    'lock-keyhole-circle',
    'lock-keyhole-square',
    'lock-unlocked-01',
    'log-in',
    'log-out',
    'luggage-01',
    'luggage-03',
    'magic-wand-01',
    'magic-wand-02',
    'mail-01',
    'mail-04',
    'map-01',
    'mark',
    'marker-pin-01',
    'marker-pin-04',
    'marker-pin-05',
    'marker-pin-06',
    'maximize-01',
    'maximize-02',
    'medical-circle',
    'medical-cross',
    'menu-01',
    'menu-02',
    'menu-close-01',
    'message-alert-square',
    'message-chat-circle',
    'message-chat-square',
    'message-check-square',
    'message-circle-02',
    'message-dots-square',
    'message-heart-square',
    'message-notification-square',
    'message-plus-square',
    'message-question-square',
    'message-smile-square',
    'message-square-02',
    'message-square-filled-02',
    'message-text-square-02',
    'message-x-square',
    'microphone-01',
    'microphone-off-01',
    'microscope',
    'minimize-01',
    'minimize-02',
    'minus-circle',
    'minus',
    'monitor-01',
    'monitor-03',
    'monitor-05',
    'moon-01',
    'moon-02',
    'moon-eclipse',
    'moon-star',
    'mouse',
    'move',
    'music-note-01',
    'music-note-02',
    'music',
    'navigation-pointer-01',
    'navigation-pointer-02',
    'navigation-pointer-off-01',
    'navigation-pointer-off-02',
    'notification-box',
    'notification-text',
    'number-list',
    'octagon',
    'package-check',
    'package-minus',
    'package-plus',
    'package-search',
    'package-x',
    'package',
    'paint-pour',
    'paint',
    'palette',
    'paperclip',
    'paragraph-spacing',
    'paragraph-wrap',
    'passcode-lock',
    'passcode',
    'passport',
    'pause-circle',
    'pause-square',
    'payment-upi',
    'pen-tool-02',
    'pen-tool-minus',
    'pen-tool-plus',
    'pencil-01',
    'pencil-line',
    'pentagon',
    'percent-01',
    'percent-03',
    'perspective-01',
    'perspective-02',
    'phone-call-01',
    'phone-hang-up',
    'phone-incoming-02',
    'phone-outgoing-02',
    'phone-plus',
    'phone-x',
    'phone',
    'pie-chart-01',
    'pie-chart-02',
    'pie-chart-03',
    'piggy-bank-01',
    'pilcrow-01',
    'pin-filled',
    'pin',
    'placeholder',
    'plane',
    'play-circle',
    'play-filled',
    'play-square',
    'play',
    'plus-circle',
    'plus',
    'podcast',
    'podium',
    'power-01',
    'power-02',
    'presentation-chart-01',
    'printer',
    'public-page',
    'puzzle-piece-02',
    'puzzle-piece',
    'qr-code-02',
    'rackets-ellipses',
    'rackets-plus',
    'razorpay',
    'receipt-check',
    'receipt',
    'recording-01',
    'recording-02',
    'recording-03',
    'reduce-width',
    'reflect-01',
    'reflect-02',
    'refresh-ccw-01',
    'refresh-cw-01',
    'refresh-cw-04',
    'refresh-cw-05',
    'reorder',
    'repeat-01',
    'repeat-02',
    'right-indent-01',
    'right-indent-02',
    'rocket-02',
    'roller-brush',
    'route',
    'rows-01',
    'rows-02',
    'rows-03',
    'rss-01',
    'ruler',
    'safe',
    'sale-01',
    'sale-02',
    'save-01',
    'scale-01',
    'scale-02',
    'scale-03',
    'scales-02',
    'scan',
    'scissors-01',
    'scissors-cut-01',
    'search-md',
    'send-01',
    'send-03',
    'send-first-01',
    'send-magic-01',
    'send-magic-03',
    'send-second-01',
    'server-01',
    'server-02',
    'server-03',
    'server-04',
    'server-05',
    'server-06',
    'settings-01',
    'settings-04',
    'share-01',
    'share-android',
    'share-forward',
    'shield-01',
    'shield-dollar',
    'shield-off',
    'shield-plus',
    'shield-tick',
    'shield-zap',
    'shopping-bag-01',
    'shopping-bag-02',
    'shopping-cart-01',
    'shuffle-01',
    'signal-01',
    'signal-02',
    'signal-03',
    'simcard',
    'skew',
    'skip-back',
    'skip-forward',
    'slash-circle-01',
    'sliders-01',
    'snowflake-01',
    'snowflake-02',
    'spacing-height-01',
    'spacing-height-02',
    'spacing-width-01',
    'spacing-width-02',
    'speaker-01',
    'speaker-02',
    'speedometer-01',
    'speedometer-03',
    'spinning-wheel',
    'square',
    'stand',
    'star-04',
    'star-05',
    'star-06',
    'star-07',
    'stars-01',
    'stars-02',
    'stars-03',
    'stars-filled-01',
    'stars-filled-02',
    'sticker-circle',
    'sticker-square',
    'stop-circle',
    'stop-filled',
    'stop-square',
    'stop',
    'strikethrough-01',
    'subscript',
    'sun-setting-01',
    'sun-setting-03',
    'sun',
    'sunrise',
    'sunset',
    'switch-horizontal-01',
    'switch-horizontal-02',
    'switch-vertical-01',
    'switch-vertical-02',
    'table',
    'tablet-01',
    'tag-01',
    'target-02',
    'target-04',
    'telescope',
    'terminal-browser',
    'terminal-circle',
    'terminal-square',
    'terminal',
    'text-input',
    'thermometer-01',
    'thermometer-02',
    'thermometer-03',
    'thermometer-cold',
    'thermometer-warm',
    'thumbs-down',
    'thumbs-up',
    'tick',
    'ticket-01',
    'token-gate',
    'tool-01',
    'tool-02',
    'train',
    'tram',
    'transform',
    'translate-01',
    'trash-01',
    'trend-down-01',
    'trend-up-01',
    'triangle',
    'trophy-01',
    'truck-02',
    'tv-02',
    'type-01',
    'type-strikethrough-01',
    'umbrella-02',
    'umbrella-03',
    'underline-01',
    'unfeature',
    'unpin-filled',
    'unpin',
    'upload-cloud-01',
    'usb-flash-drive',
    'user-01',
    'user-check-01',
    'user-circle',
    'user-down-01',
    'user-edit',
    'user-left-01',
    'user-minus-01',
    'user-money',
    'user-plus-01',
    'user-right-01',
    'user-square',
    'user-up-01',
    'user-x-01',
    'users-01',
    'users-check',
    'users-download',
    'users-edit',
    'users-left',
    'users-minus',
    'users-money',
    'users-plus',
    'users-right',
    'users-up',
    'users-x',
    'variable',
    'video-courses',
    'video-recorder-off',
    'video-recorder',
    'voicemail',
    'volume-max',
    'volume-min',
    'volume-minus',
    'volume-plus',
    'volume-x',
    'wallet-01',
    'wallet-02',
    'watch-circle',
    'waves',
    'waving-hand',
    'wifi-off',
    'wifi',
    'wind-01',
    'wind-02',
    'wind-03',
    'x-circle-filled',
    'x-circle',
    'x-close',
    'youtube',
    'zap-fast',
    'zap-off',
    'zap',
    'zoom-in',
    'zoom-out'
  ],
  country: [
    'aed',
    'ars',
    'aud',
    'brl',
    'cad',
    'clp',
    'cop',
    'eur',
    'gbp',
    'huf',
    'idr',
    'ils',
    'inr',
    'jpy',
    'mxn',
    'myr',
    'pen',
    'php',
    'sgd',
    'usd',
    'uyu'
  ],
  logo: [
    'nas-io-logo-coloured',
    'nas-io-logo-grayscale',
    'nas-io-logo-with-text-coloured',
    'nas-io-logo-with-text-grayscale',
    'nas-io-logo-with-text-white'
  ],
  'payment-methods': [
    'alipay',
    'amazon',
    'amex',
    'applePay',
    'bitPay',
    'cabal',
    'carnet',
    'cmr',
    'credit-card-02',
    'credit-card-filled',
    'diners',
    'ebanx',
    'ebanx_boleto',
    'ebanx_card',
    'ebanx_efecty',
    'ebanx_grayscale',
    'ebanx_mercado',
    'ebanx_nequi',
    'ebanx_nu',
    'ebanx_oxxo',
    'ebanx_pagoefectivo',
    'ebanx_pix',
    'ebanx_safetypay',
    'ebanx_spei',
    'elo',
    'gCash',
    'googlePay',
    'googlePay2',
    'grabPay',
    'hipercard',
    'jcb',
    'maestro',
    'mastercard-black',
    'mastercard',
    'naranja',
    'payMaya',
    'payTm',
    'paypal-greyscale',
    'paypal',
    'paype',
    'pix-full',
    'pix',
    'razorpay-icon-only',
    'razorpay',
    'razorpay_greyscale',
    'shopeePay',
    'sodexo',
    'stripe-grayscale',
    'stripe',
    'unionPay',
    'upi-coloured',
    'upi',
    'visa',
    'xendit'
  ],
  social: [
    'airbnb-coloured',
    'airbnb-neutral',
    'apple-neutral',
    'coinbase-coloured',
    'discord-coloured-circle',
    'discord-coloured',
    'discord-neutral',
    'discord-role-coloured',
    'dribbble-coloured',
    'dribbble-neutral',
    'facebook-coloured',
    'facebook-neutral',
    'google-calendar',
    'google-coloured',
    'google-neutral',
    'instagram-coloured',
    'instagram-neutral',
    'line-coloured',
    'line-neutral',
    'linkedin-coloured',
    'linkedin-neutral',
    'medium-neutral',
    'messenger-neutral',
    'metamask-coloured',
    'opensea-coloured',
    'opensea-neutral',
    'patreon-neutral',
    'slack-coloured',
    'slack-neutral',
    'snapchat-neutral',
    'solana-coloured',
    'spotify-coloured',
    'spotify-neutral',
    'telegram-coloured',
    'telegram-neutral',
    'tiktok-coloured',
    'tiktok-neutral',
    'twitter-coloured',
    'twitter-neutral',
    'whatsapp-coloured',
    'whatsapp-neutral',
    'youtube-coloured',
    'youtube-neutral'
  ]
} as const;
