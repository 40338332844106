import type { NPLIconButtonSizeType } from './types';

export const getPaddingClassBySize = (
  size: NPLIconButtonSizeType
): string => {
  switch (size) {
    case 'xs':
      return 'p-4';
    case 'sm':
      return 'p-6';
    case 'md':
      return 'p-10';
    case 'lg':
      return 'p-12';
    default:
      return 'p-16';
  }
};

export const getIconSizeByButtonSize = (
  size: NPLIconButtonSizeType
): number => {
  switch (size) {
    case 'xs':
    case 'sm':
    case 'md':
      return 20;
    case 'lg':
    case 'xl':
      return 24;
  }
};
