import axios from 'axios';
import { getLocaleFromCookie } from '@/utility/localization';
import { getAxiosErrorMsg } from '.';

const publicAxiosRequest = axios.create();

const requestMiddleware = (config) => {
  const locale = getLocaleFromCookie();

  if (locale && config?.headers) {
    config.headers['Accept-Language'] = locale;
  }

  return config;
};

const requestErrorMiddleware = (error) => {
  return Promise.reject(error);
};

const responseMiddleware = (response) => response;

const responseErrorMiddleware = async (resError) => {
  try {
    const status = resError?.response?.status;

    return { error: getAxiosErrorMsg(resError), data: {}, status };
  } catch (error) {
    return { error: getAxiosErrorMsg(error), data: {} };
  }
};

// Request interceptors
publicAxiosRequest.interceptors.request.use(
  requestMiddleware,
  requestErrorMiddleware
);

// Response interceptors
publicAxiosRequest.interceptors.response.use(
  responseMiddleware,
  responseErrorMiddleware
);

export default publicAxiosRequest;
