import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import { isLocalHost } from './serverHelpers';
import { getPrimaryDomain } from './stringHelper';
import type { OptionsType } from 'cookies-next/lib/types';

class CookieService {
  static set(key: string, value: string, options: OptionsType = {}) {
    let defaultOptions: OptionsType = {
      path: '/',
      domain: getPrimaryDomain(),
      ...options
    };

    if (!isLocalHost()) {
      defaultOptions = {
        ...defaultOptions,
        sameSite: 'none',
        secure: true
      };
    }

    setCookie(key, value, defaultOptions);
  }

  static get(key: string, options: OptionsType = {}) {
    return getCookie(key, { domain: getPrimaryDomain(), ...options });
  }

  static delete(key: string, options: OptionsType = {}) {
    deleteCookie(key, {
      path: '/',
      domain: getPrimaryDomain(),
      ...options
    });
  }
}

export default CookieService;
